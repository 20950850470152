import React, {useEffect, useState} from "react";
import "./styles.scss";
import {useWindowWidth} from "../../../services/hooks";
import NewButton from "../../NewButton";

const SpringPcpBanner = () => {
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(width <= 768)
  }, [width])

  return (
    <div className="spring-sale-pcp-banner">
      <h3 className="spring-promo-text">spring is in sight</h3>
      <h2 className="percent-off">40% OFF</h2>
      <span className="sale-description">Shop our selection of contact lenses from popular brands and save on your first order.</span>
      <span className="limited-time-text">{isMobile
        ? "limited time only\nnow through april 1"
        : "limited time only — now through april 1"
      }</span>
    </div>
  )
}

export default SpringPcpBanner
